<template>
    <div class="protocal-box">
        <MenuList 
            :son_id="state.son_id" 
            :fater_id="state.fater_id"
            @getDetail="getDetail" />
        <div class="protocal-content">
            <strong class="protocal-content-title">{{ state.info.son_title }}</strong>
            <div class="protocal-content-content html-from-editor" v-html="state.content"></div>
        </div>
    </div>
</template>

<script setup>
import MenuList from "@@/protocal/menu.vue"

import { getprotocalFaterSonDetail } from "@/api/index"
import { reactive, onMounted } from "vue"
import { useRoute } from "vue-router";
import cont_match_reg from 'md-base-tools/editor'
import { decodeEntities } from "@/utils/tools.js"

const state = reactive({
    son_id: '',
    fater_id: '',
    info: {},
    content: '',
})

const route = useRoute();
onMounted(() => {
    state.son_id = route.params.son_id
    state.fater_id = route.params.fater_id

    getDetail({fater_id: state.fater_id, son_id: state.son_id})
})

const getDetail = (params) => {
    state.fater_id = params.fater_id
    state.son_id = params.son_id
    getprotocalFaterSonDetail({
        fater_id: params.fater_id,
        son_id: params.son_id
    }).then(res => {
        if (res.status == 0) {
            state.info = res.data
            document.title = res.data.son_title
            let cont = state.info.content
            if (cont.indexOf('&gt;') > -1){
                cont = decodeEntities(cont)
            }
            state.content = cont_match_reg(cont, process.env.VUE_APP_ENV, 800, 'project').innerHTML
        }
    })
}

</script>

<style lang="scss">
.protocal-box {
    width: 1080px;
    margin: 40px auto;
    display: flex;
    .protocal-content {
        width: 0;
        flex: 1;
        line-height: 24px;
        font-size: 16px;
        &-title {
            display: block;
            font-size: 24px;
            line-height: 40px;
            padding-bottom: 10px;
            border-bottom: 1px solid #E8E8E8;
            margin-bottom: 36px;
        }
    }
    // .protocal-content-content {
    //     .p-padding span {
    //         font-family: PingFang SC-Regular, PingFang SC;
    //         font-size: 16px !important;
    //     }
    // }
}
</style>
