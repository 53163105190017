<template>
    <div class="menu-list">
        <div v-for="(item, index) in state.list" :key="index">
            <div class="father-box" @click="changeShow(index)">
                <span :class="['icons', item.icon_type]"></span>
                {{ item.title }}
                <span :class="['icon-arrowup icons icons-right', {'rotated': item.show}]"></span>
            </div>
            <el-collapse-transition>
                <div v-show="item.show && item.son_info && item.son_info.length">
                    <div
                        :class="['son-box', { 'active': item.fater_id == props.fater_id && sitem.son_id == props.son_id }]" 
                        v-for="(sitem, sindex) in item.son_info" 
                        :key="sindex"
                        @click="changeProtocal(item, sitem)">
                        {{ sitem.son_title }}
                    </div>
                </div>
            </el-collapse-transition>
        </div>
    </div>
</template>

<script setup>
import { getprotocalList } from "@/api/index"

import { defineProps, reactive, onMounted, defineEmits } from "vue"
import { useRouter } from "vue-router";

const router = useRouter()

const props = defineProps(['son_id', 'fater_id'])

const state = reactive({
    list: []
})

onMounted(() => {
    getList()
})

const getList = () => {
    getprotocalList().then(res=> {
        if(res.status == 0) {
            state.list = res.data
            state.list.map(item => {
                if (item.fater_id == props.fater_id) {
                    item.show = true
                }
            })
        }
    })
}

const changeShow = (index) => {
    state.list[index].show = !state.list[index].show
}

const emit = defineEmits(['getDetail']);
const changeProtocal = (item, sitem) => {
    console.log(sitem)
    if(sitem.link) {
        window.open(sitem.link)
    } else {
        router.push(`/protocal/${item.fater_id}/${sitem.son_id}`)
        emit('getDetail', { fater_id: item.fater_id, son_id: sitem.son_id })
    }
}

</script>

<style lang="scss" scoped>
.menu-list {
    width: 240px;
    user-select: none;
    margin: 0 40px 0;
    padding: 30px 10px;
    background-color: #F8F8F8;
    border-radius: 8px;
    font-family: PingFang SC-Light, PingFang SC;
}
.father-box {
    font-size: 16px;
    font-weight: 500;
    padding: 20px 0 0 36px;
    position: relative;
    margin-bottom: 12px;
    cursor: pointer;
    .icons {
        position: absolute;
        left: 10px;
        top: 22px;
        transition: transform 0.3s;
        &.icons-right {
            left: auto;
            right: 10px;
        }
        &.rotated {
            transform: rotate(180deg);
        }
    }
}
.son-box {
    cursor: pointer;
    line-height: 22px;
    padding: 5px 0 5px 26px;
    font-size: 14px;
    &:hover, &.active {
        background-color: #E8E8E8;
        font-weight: bold;
        border-radius: 4px;
    }
}
</style>
